import { Typography } from '@creditas/typography';
import { styled } from '@creditas/stylitas';
import { gridMedia } from '../../../../utils/grid-utils';

export const gridPageTitle = {
  gridColumn: gridMedia('1 / 12', '1 / 12'),
  gridRow: gridMedia('1', '1'),
};

export const StyledTypography = styled(Typography)`
  margin-bottom: 15px;
`;
