import React, { useState } from 'react';
import { Action } from '@creditas/icons';
import { RemoveLoanSelectionValidationModal } from '../RemoveLoanSelectionValidationModal';

interface RemoveLoanBlockingButtonProps {
  selectionGroupId: string;
  removeLoanSelection: (selectionGroupId: string) => Promise<void>;
}

export const RemoveLoanSelectionButton: React.FC<RemoveLoanBlockingButtonProps> = ({
  selectionGroupId,
  removeLoanSelection,
}) => {
  const [
    showRemoveLoanSelectionValidationModal,
    setShowRemoveLoanSelectionValidationModal,
  ] = useState(false);

  return (
    <>
      <Action.Trash2
        color="gray"
        data-testid="remove-loan-selection"
        fontSize="large"
        onClick={(): void => setShowRemoveLoanSelectionValidationModal(true)}
      />
      <RemoveLoanSelectionValidationModal
        data-testid="delete-selection-modal"
        selectionGroupId={selectionGroupId}
        removeLoanSelection={removeLoanSelection}
        showRemoveModal={showRemoveLoanSelectionValidationModal}
        setShowRemoveModal={setShowRemoveLoanSelectionValidationModal}
      />
    </>
  );
};
