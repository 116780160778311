import React from 'react';

import { TableRow } from '@creditas/table';
import times from 'lodash.times';
import { File } from '@creditas/icons';
import { useTranslation } from '@creditas/i18n';
import { useToaster } from '@creditas/toaster';
import { useAsyncCallback } from 'react-async-hook';
import { AxiosResponse } from 'axios';
import { TableCell } from './Table.style';
import { LoanSelectionsResponse } from '../../../../@types/loan-selection';
import { getAssignors, getAssignees } from '../../../../services/fund/Fund';
import LoadableContent from '../../../../modules/LoadableContent';
import {
  downloadLoanSelectionFile,
  removeLoanSelection,
} from '../../../../services/file-engine/FileEngine';
import { RemoveLoanSelectionButton } from '../RemoveLoanSelectionButton';

interface TableProps {
  data?: LoanSelectionsResponse;
  loading: boolean;
  pageSize?: number;
  getLoanSelections: () => Promise<AxiosResponse<LoanSelectionsResponse>>;
}

export const TableData: React.FC<TableProps> = ({
  data,
  loading,
  pageSize = 10,
  getLoanSelections,
}) => {
  const { push } = useToaster();
  const { t } = useTranslation();
  const assignees = getAssignees().map(assignee => {
    return { id: assignee.id, name: assignee.name };
  });
  const assignors = getAssignors().map(assignor => {
    return { id: assignor.id, name: assignor.name };
  });

  const { execute: downloadLoanSelectionFileFunction } = useAsyncCallback(
    downloadLoanSelectionFile,
    {
      onSuccess: () => push(t('pages.loanSelection.toaster.downloadSuccess'), { level: 'success' }),
      onError: () => push(t('pages.loanSelection.toaster.downloadError'), { level: 'danger' }),
    },
  );

  const removeLoanSelectionCallback = useAsyncCallback(removeLoanSelection, {
    onSuccess: () => {
      getLoanSelections();
      push(t('pages.loanSelection.toaster.removeLoanSelectionSuccess'), { level: 'success' });
    },
    onError: () =>
      push(t('pages.loanSelection.toaster.removeLoanSelectionError'), { level: 'danger' }),
  });

  return (
    <>
      {loading
        ? times(pageSize, (index: number) => (
            <TableRow data-testid="skeleton-table-row" key={index}>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  bottomSpacing={16}
                  topSpacing={16}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  bottomSpacing={16}
                  topSpacing={16}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  bottomSpacing={16}
                  topSpacing={16}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  bottomSpacing={16}
                  topSpacing={16}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  bottomSpacing={16}
                  topSpacing={16}
                />
              </TableCell>
              <TableCell>
                <LoadableContent
                  loading={loading}
                  isRelativeWidth
                  minWidth={50}
                  bottomSpacing={16}
                  topSpacing={16}
                />
              </TableCell>
            </TableRow>
          ))
        : data?.content.map(loanSelection => (
            <TableRow data-testid="data-table-row" key={loanSelection.selectionGroupId}>
              <TableCell>
                {assignors.find(assignor => assignor.id === loanSelection.assignorId)?.name}
              </TableCell>
              <TableCell>
                {assignees.find(assignee => assignee.id === loanSelection.assigneeId)?.name}
              </TableCell>
              <TableCell>{loanSelection.productType}</TableCell>
              <TableCell>{loanSelection.totalAmount}</TableCell>
              <TableCell>{loanSelection.numberOfLoans}</TableCell>
              <TableCell>{loanSelection.createdAt}</TableCell>
              <TableCell>
                <div style={{ cursor: 'pointer', display: 'inline-block' }}>
                  <File.DownloadCloud
                    data-testid="download-button"
                    color="cornflowerblue"
                    fontSize="large"
                    onClick={(): Promise<void> => {
                      if (loanSelection.selectionGroupId) {
                        return downloadLoanSelectionFileFunction(
                          loanSelection.selectionGroupId,
                          loanSelection.productType,
                        );
                      }
                      return Promise.resolve();
                    }}
                  />
                </div>
                <RemoveLoanSelectionButton
                  removeLoanSelection={removeLoanSelectionCallback.execute}
                  selectionGroupId={
                    loanSelection.selectionGroupId ? loanSelection.selectionGroupId : ''
                  }
                />
              </TableCell>
            </TableRow>
          ))}
    </>
  );
};
