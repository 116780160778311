import { FundResponse } from '../../@types/fund';

const creditors: FundResponse[] = [
  {
    id: 'FUN-3A442071-9DAE-4373-AB65-2275603A38CC',
    name: 'SCD',
    category: 'UNDERWRITER',
    products: ['auto', 'moto', 'pension', 'payroll', 'salary_anticipation'],
    active: true,
  },
  {
    id: 'FUN-510474DD-753F-4BEE-8D1C-F9C8B6AFE454',
    name: 'SOCINAL',
    category: 'UNDERWRITER',
    products: ['pension'],
    active: true,
  },
  {
    id: 'FUN-01348760-5EE9-4315-BE63-C102B8C51C99',
    name: 'SOROCRED',
    category: 'UNDERWRITER',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-1FC201F2-F16F-4522-9F70-6CD713BADAF8',
    name: 'CHP',
    category: 'UNDERWRITER',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-BECBBB3F-6CC4-415A-B8CC-9C61688192FF',
    name: 'SANTANA',
    category: 'UNDERWRITER',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-C29FD3AD-81D3-464D-9DCA-D5761FFA1CE4',
    name: 'FAPA',
    category: 'UNDERWRITER',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-3A7939C1-D698-45CF-9A51-0887E01CEDD0',
    name: 'FIDC Tempus Auto',
    category: 'WAREHOUSE',
    products: ['auto', 'moto'],
    active: false,
  },
  {
    id: 'FUN-36927AE4-E8EF-49B5-8C1A-6DA2BD277709',
    name: 'FIDC Tempus Home',
    category: 'WAREHOUSE',
    products: ['home', 'bcredi'],
    active: false,
  },
  {
    id: 'FUN-5600CEA0-82A7-11EB-8DCD-0242AC130003',
    name: 'FIDC Tempus II',
    category: 'WAREHOUSE',
    products: ['auto', 'moto', 'pension', 'home', 'bcredi', 'payroll', 'salary_anticipation'],
    active: true,
  },
  {
    id: 'FUN-0FD2FF0F-5679-47C1-AF5E-F5FE9E747971',
    name: 'FIDC Tempus III',
    category: 'WAREHOUSE',
    products: ['auto', 'moto', 'pension', 'home', 'bcredi', 'payroll'],
    active: true,
  },
  {
    id: 'FUN-4373FB2A-86CA-4EE0-9D75-3C678CB1CF4E',
    name: 'FIDC Empirica Home Equity',
    category: 'FINAL',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-698F1AC1-F921-4D76-83DE-3E964B849B5C',
    name: 'FIDC ASTAT',
    category: 'FINAL',
    products: ['home', 'bcredi'], // validar
    active: true,
  },
  {
    id: 'FUN-87E86D37-288E-4054-82DE-81469993989E',
    name: 'Aloha I',
    category: 'WAREHOUSE',
    products: ['auto', 'moto', 'home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-2B4A7D41-5859-49CC-B76C-9DEDC6867406',
    name: 'Aloha II',
    category: 'WAREHOUSE',
    products: ['auto', 'moto', 'home', 'bcredi'],
    active: false,
  },
  {
    id: 'FUN-DCAF6342-FBB5-4630-BC9D-9515AB99ACED',
    name: 'Aloha III',
    category: 'WAREHOUSE',
    products: ['auto', 'moto', 'home', 'bcredi'],
    active: false,
  },
  {
    id: 'FUN-6B9E5A1E-FDFA-46F0-A5A6-18A4A6BA43FE',
    name: 'FIDC Creditas Auto I',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-3CA8F666-1D35-4E8D-B39D-DA4A2AF8A4C3',
    name: 'FIDC Creditas Auto II',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-6F063716-31B6-47A7-A081-A2922DE065B7',
    name: 'FIDC Creditas Auto III',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-2323E26D-32E4-43EB-B30A-A82EFC644889',
    name: 'FIDC Creditas Auto IV',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-16F64D2E-D8E8-11EB-BF4A-FAFFC23001E9',
    name: 'FIDC Creditas Auto V',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-A6B3BE30-A5C4-4A62-86DD-A57CEB884566',
    name: 'FIDC Creditas Auto VI',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-6F0A5701-4F97-4DE4-8973-106CB425C316',
    name: 'FIDC Creditas Auto VII',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-84621D0D-DC34-4A1B-AAB8-5B9AFE9DC822',
    name: 'FIDC Creditas Auto VIII',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-79E5244A-98BB-4DB2-B19B-01624E397419',
    name: 'FIDC Creditas Auto IX',
    category: 'FINAL',
    products: ['auto', 'moto'],
    active: true,
  },
  {
    id: 'FUN-BA78C29E-C7CF-11EB-A04A-7F007BDFFD2E',
    name: 'CRI True',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-C9060D44-C7CF-11EB-A5D7-6B763C93D978',
    name: 'CRI Vert 1',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-CD162EC8-C7CF-11EB-89D3-E3A1CCC33018',
    name: 'CRI Vert 2',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-D112A6BE-C7CF-11EB-B8AF-CFE91E12D079',
    name: 'CRI Vert 3',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-D4643E5E-C7CF-11EB-B0F7-5703D27404EA',
    name: 'CRI Vert 4',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-D815359E-C7CF-11EB-9946-17BBFC6E72E6',
    name: 'CRI Vert 5',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-A86E6E5B-F082-46B8-84C0-493EE848A91F',
    name: 'CRI Vert 6',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-C6374F72-D8FC-11EB-BF4A-FAFFC23001E9',
    name: 'CRI Vert 7',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-D6E4A3EC-D8FC-11EB-BF4A-FAFFC23001E9',
    name: 'CRI Vert 8',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-A932C8CB-1C5F-4537-994A-F0A115D52E8D',
    name: 'CRI Vert 9',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-BFDCECFD-AA3A-4C84-A524-5344511EEFD3',
    name: 'CRI Vert 10',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-6E04A1F7-1DE9-47EE-B438-6C46331D1055',
    name: 'CRI Vert 11',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-F9D08DF8-CFDC-4A07-9C17-66E5F4C6A2A7',
    name: 'CRI Vert 12',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-2269DFBC-9E07-458A-BB9D-7C98B7BD6061',
    name: 'CRI Vert 13',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-B82E94D0-3C88-41CA-8EDF-62945A1275FE',
    name: 'CRI Vert 14',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-9FC1CDF9-946A-4AA4-8C3D-332327DB14C1',
    name: 'CRI Vert 15',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-1094F613-417F-408B-BAF2-F6C0342BB681',
    name: 'CRI Vert 16',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-2A9A7DEC-BA01-40F5-8131-6BA75BD4239B',
    name: 'CRI Vert 17',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-795344D3-4B4B-41D3-A1E3-4AB846D40482',
    name: 'CRI Vert 18',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-0E5A2CBC-4F34-4142-B509-DC1B0A87D4CD',
    name: 'CRI Vert 19',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-9ACA7C6A-4129-461C-B953-4B18D21FE4E1',
    name: 'CRI Vert 20',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-4D157A24-992C-43BC-A076-D436717EEDC1',
    name: 'CRI Vert 21',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-8142FC22-2E86-4B07-90F7-4D4C88B1F1F9',
    name: 'CRI Vert 22',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-560C0FCC-5E31-4C71-9736-750787FE2C09',
    name: 'Andbank',
    category: 'FINAL',
    products: ['auto', 'moto', 'pension', 'payroll', 'home'],
    active: true,
  },
  {
    id: 'FUN-36EB84AF-996A-4D07-B78B-4BCAD280539A',
    name: 'FIDC Angá',
    category: 'FINAL',
    products: ['pension', 'payroll'],
    active: true,
  },
  {
    id: 'FUN-B4387A76-FD17-4A7C-BAEC-F1132616B519',
    name: 'CRI Vert 23',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-7E0ACBB4-77D4-482B-9E59-FEBD92BCE578',
    name: 'CRI Vert 24',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-FA289B44-8557-4CE9-9BF1-8557A03759F9',
    name: 'CRI Vert 25',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-A11DFE38-5011-4FDD-B726-B92694935059',
    name: 'CRI Vert 26',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-EE643F5C-2565-4E12-9D4F-51FAE1188BC6',
    name: 'CRI Vert 27',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-D7C0E870-EF4F-4AD9-AE36-0F7F8D6C7E80',
    name: 'CRI Vert 28',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-B6C694C7-B428-4204-92F9-086355D2D895',
    name: 'CRI Vert 29',
    category: 'CRI',
    products: ['home', 'bcredi'],
    active: true,
  },
  {
    id: 'FUN-6F81EF6D-1865-4D81-92F6-7DF02B4379E8',
    name: 'CHP Warehouse',
    category: 'WAREHOUSE',
    products: ['home', 'bcredi'],
    active: true,
  },
];
export const getAssignors = (): FundResponse[] => creditors;

export const getAssignorById = (id: string): FundResponse | undefined => {
  return getAssignors().find(assignor => assignor.id === id);
};

export const getAssignees = (): FundResponse[] =>
  creditors.filter(creditor => creditor.category !== 'UNDERWRITER');

export const getAssigneeById = (id: string): FundResponse | undefined => {
  return getAssignees().find(assignee => assignee.id === id);
};
