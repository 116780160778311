import React from 'react';
import { TableRow } from '@creditas/table';
import { GridItem } from '@creditas/layout';
import { PaginationTable } from '@creditas/pagination';

import { AxiosResponse } from 'axios';
import {
  StyledCreditasTable,
  TableCell,
  TableHead,
  TableBody,
  gridTable,
  gridPaginationTable,
} from './Table.style';
import { LoanSelectionsResponse } from '../../../../@types/loan-selection';
import { TableData } from './TableData';

type updateType = 'replace' | 'replaceIn' | 'push' | 'pushIn' | undefined;

interface TableProps {
  data?: LoanSelectionsResponse;
  loading: boolean;
  headers: string[];
  page: number;
  pageSize: number;
  setPage: (page: number, updateType?: updateType) => void;
  setPageSize: (pageSize: number, updateType?: updateType) => void;
  getLoanSelections: () => Promise<AxiosResponse<LoanSelectionsResponse>>;
}

export const Table: React.FC<TableProps> = ({
  data,
  loading,
  headers,
  page,
  pageSize,
  setPage,
  setPageSize,
  getLoanSelections,
}) => {
  return (
    <>
      <GridItem gridColumn={gridTable.gridColumn} gridRow={gridTable.gridRow}>
        <StyledCreditasTable fullWidth>
          <TableHead>
            <TableRow data-testid="header-table-row">
              {headers.map(headerTitle => (
                <TableCell textAlign="left" key={headerTitle}>
                  {headerTitle}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody variant="striped" stripedColorOdd="#e0e0e0">
            <TableData
              data={data}
              loading={loading}
              pageSize={pageSize}
              getLoanSelections={getLoanSelections}
            />
          </TableBody>
        </StyledCreditasTable>
      </GridItem>
      <GridItem gridColumn={gridPaginationTable.gridColumn} gridRow={gridPaginationTable.gridRow}>
        <PaginationTable
          current={page}
          pageSize={pageSize}
          total={data?.totalPages}
          onChangePage={(newPage: number): void => setPage(newPage, 'pushIn')}
          onChangePageSize={(newPageSize: number): void => {
            setPageSize(newPageSize, 'pushIn');
            setPage(1, 'replaceIn');
          }}
        />
      </GridItem>
    </>
  );
};
