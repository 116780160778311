import React from 'react';
import { GridItem } from '@creditas/layout';
import { useTranslation } from '@creditas/i18n';
import { Typography } from '@creditas/typography';
import { AxiosResponse } from 'axios';
import { Modal } from '../../../../modules/Modal';
import {
  ConfirmLoanSelectionForm,
  gridContainerOptions,
  StyledButton,
  SummaryContent,
  gridItemModalTitle,
  gridSummary,
  gridCancelButton,
  gridConfirmButton,
} from './ConfirmLoanSelectionModal.style';
import { LoanSelectionResponse, LoanSelectionRequest } from '../../../../@types/loan-selection';
import { getAssignees, getAssignors } from '../../../../services/fund/Fund';

interface AddLoanBlockingButtonProps {
  loanSelection: LoanSelectionResponse;
  loanSelectionRequest: LoanSelectionRequest;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  performLoanSelection: (
    request: LoanSelectionRequest,
  ) => Promise<AxiosResponse<LoanSelectionResponse>>;
}

export const ConfirmLoanSelectionModal: React.FC<AddLoanBlockingButtonProps> = ({
  showModal,
  setShowModal,
  loanSelection,
  loanSelectionRequest,
  performLoanSelection,
}) => {
  const { t } = useTranslation();

  const assignee = getAssignees().find(element => element.id === loanSelection.assigneeId);
  const assignor = getAssignors().find(element => element.id === loanSelection.assignorId);

  return (
    <Modal canShow={showModal} hide={(): void => setShowModal(false)}>
      <ConfirmLoanSelectionForm options={gridContainerOptions}>
        <GridItem gridColumn={gridItemModalTitle.gridColumn} gridRow={gridItemModalTitle.gridRow}>
          <Typography variant="action">{t('pages.loanSelection.modal.title')}</Typography>
        </GridItem>
        <SummaryContent gridColumn={gridSummary.gridColumn} gridRow={gridSummary.gridRow}>
          <Typography variant="form">
            {t('pages.loanSelection.modal.loansSolicitationFrom', {
              assignor: assignor?.name,
              assignee: assignee?.name,
            })}
          </Typography>
          <Typography variant="form">
            {t('pages.loanSelection.modal.product', { productType: loanSelection.productType })}
          </Typography>
          <Typography variant="form">
            {t('pages.loanSelection.modal.assignmentTotalAmount', {
              totalAmount: loanSelection.totalAmount,
            })}
          </Typography>
          <Typography variant="form">
            {t('pages.loanSelection.modal.totalNumberOfLoans', {
              numberOfLoans: loanSelection.numberOfLoans,
            })}
          </Typography>
        </SummaryContent>
        <GridItem gridColumn={gridCancelButton.gridColumn} gridRow={gridCancelButton.gridRow}>
          <StyledButton
            data-testid="deny-action"
            variant="secondary"
            onClick={(): void => setShowModal(false)}
          >
            {t('cancel')}
          </StyledButton>
        </GridItem>
        <GridItem gridColumn={gridConfirmButton.gridColumn} gridRow={gridConfirmButton.gridRow}>
          <StyledButton
            data-testid="accept-action"
            onClick={(): void => {
              performLoanSelection({
                ...loanSelectionRequest,
                selection: true,
              });
              setShowModal(false);
            }}
          >
            {t('confirm')}
          </StyledButton>
        </GridItem>
      </ConfirmLoanSelectionForm>
    </Modal>
  );
};
