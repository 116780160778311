import { Button } from '@creditas/button';
import { Typography } from '@creditas/typography';
import React from 'react';

import { useTranslation } from '@creditas/i18n';
import { ConfimationContainer } from './RemoveLoanSelectionValidationModal.style';
import { Modal } from '../../../../modules/Modal';

interface RemoveLoanSelectionValidationProps {
  selectionGroupId: string;
  removeLoanSelection: (selectionGroupId: string) => Promise<void>;
  showRemoveModal: boolean;
  setShowRemoveModal: (value: boolean) => void;
}

export const RemoveLoanSelectionValidationModal: React.FC<RemoveLoanSelectionValidationProps> = ({
  removeLoanSelection,
  showRemoveModal,
  setShowRemoveModal,
  selectionGroupId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal canShow={showRemoveModal} hide={(): void => setShowRemoveModal(false)}>
      <ConfimationContainer>
        <Typography>{t('pages.loanSelection.questions.removeValidation')}</Typography>
      </ConfimationContainer>
      <ConfimationContainer>
        <Button data-testid="deny-action" secondary onClick={(): void => setShowRemoveModal(false)}>
          {t('no')}
        </Button>
        <Button
          data-testid="accept-action"
          onClick={(): void => {
            removeLoanSelection(selectionGroupId);
            setShowRemoveModal(false);
          }}
        >
          {t('yes')}
        </Button>
      </ConfimationContainer>
    </Modal>
  );
};
