import { styled } from '@creditas/stylitas';
import {
  Table as CreditasTable,
  TableHead as CreditasTableHead,
  TableCell as CreditasTableCell,
  TableBody as CreditasTableBody,
} from '@creditas/table';
import { Theme } from '../../../../interfaces/theme';
import { gridMedia } from '../../../../utils/grid-utils';

export const StyledCreditasTable = styled(CreditasTable)`
  margin-top: 50px;
  border: 1px solid #eee;
`;

export const TableCell = styled(CreditasTableCell)`
  text-align: center;
  word-wrap: break-word;
  vertical-align: middle;
`;

export const TableHead = styled(CreditasTableHead)`
  font-weight: 600;
  background-color: white;
  text-align: center;
`;

export const TableBody = styled(CreditasTableBody)`
  tr:hover {
    outline: 1px solid ${({ theme }: { theme: Theme }): string => theme.bColorsecondary05};
    box-shadow: 0 10px 25px 0 ${({ theme }: { theme: Theme }): string => theme.bColorneutral05};
  }
`;

export const gridTable = {
  gridColumn: gridMedia('1 / 12', '1 / 12'),
  gridRow: gridMedia('3', '3'),
};

export const gridPaginationTable = {
  gridColumn: gridMedia('1 / 12', '1 / 12'),
  gridRow: gridMedia('4', '4'),
};
