import { Button } from '@creditas/button';
import { styled } from '@creditas/stylitas';
import { Grid, GridItem } from '@creditas/layout';
import { gridMedia } from '../../../../utils/grid-utils';

export const StyledButton = styled(Button)`
  text-align: center;
  font-size: 17px;
  span {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  min-width: ${(props): string => (props.minWidth ? props.minWidth : '250px')};
  height: 40px;
  padding: 0;
`;

export const ConfirmLoanSelectionForm = styled(Grid)`
  margin: 20px 30px 50px 70px;
`;

export const SummaryContent = styled(GridItem)`
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const gridItemModalTitle = {
  gridColumn: gridMedia('1 / 12', '1 / 12'),
  gridRow: gridMedia('1', '1'),
};

export const gridSummary = {
  gridColumn: gridMedia('1 / 12', '1 / 12'),
  gridRow: gridMedia('2', '2'),
};

export const gridCancelButton = {
  gridColumn: gridMedia('1 / 3', '1 / 3'),
  gridRow: gridMedia('3', '3'),
};

export const gridConfirmButton = {
  gridColumn: gridMedia('11 / 12', '11 / 12'),
  gridRow: gridMedia('3', '3'),
};

export const gridContainerOptions = {
  gridJustifyContent: 'center',
  gridColumns: {
    xs: 'repeat(12, 1fr)',
    md: 'repeat(12 , 1fr)',
  },
};
