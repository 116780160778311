import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import { api } from './Api';
import {
  LoanSelectionResponse,
  LoanSelectionRequest,
  LoanSelectionsResponse,
} from '../../@types/loan-selection';

export const performLoanSelection = async (
  requestBody: LoanSelectionRequest,
): Promise<AxiosResponse<LoanSelectionResponse>> => {
  return api.post('/loan-selections', requestBody);
};

const calculatePageIndex: (page: number) => number = page => (page > 0 ? page - 1 : page);

export const getLoanSelections = async (
  page = 0,
  size?: number,
  sortBy = 'createdAt,desc',
): Promise<AxiosResponse<LoanSelectionsResponse>> => {
  return api.get('/loan-selections', {
    params: {
      size,
      page: calculatePageIndex(page),
      sort: sortBy,
    },
  });
};

export const downloadLoanSelectionFile = async (
  selectionGroupId: string,
  productType: string,
): Promise<void> => {
  let url = `/loan-selections/${selectionGroupId}/files`;
  if (productType === 'payroll') {
    url = `/loan-selections/${selectionGroupId}/files/payroll`;
  }
  const file = (await api.get(url, {
    responseType: 'blob',
  })) as AxiosResponse<Blob>;

  const formattedDate = format(new Date(), "dd/MM/yy HH'h'mm");
  fileDownload(file.data, `Loan Selection ${formattedDate}.csv`);
};

export const removeLoanSelection = async (selectionGroupId: string): Promise<void> => {
  return api.delete(`/loan-selections/${selectionGroupId}`);
};
