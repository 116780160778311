import React, { Component, ReactElement } from 'react';
import { ThemeProvider } from '@creditas/stylitas';
import tokens from '@creditas/tokens';
import { Global } from '@creditas/global';
import { ToasterProvider } from '@creditas/toaster';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import { AppCustomProps, defaultCustomProps } from '.';
import { CustomPropsProvider } from './contexts';
import { Routes } from './Routes';
import { envVars } from './envVars';

/* eslint-disable react/prefer-stateless-function */
export class App extends Component<AppCustomProps> {
  render(): ReactElement {
    return (
      <FlagsmithProvider
        options={{
          environmentID: envVars.FLAGSMITH_ENVIRONMENT_ID,
        }}
        flagsmith={flagsmith}
      >
        <ThemeProvider theme={{ ...tokens }}>
          <ToasterProvider>
            <CustomPropsProvider value={{ ...defaultCustomProps, ...this.props }}>
              <Global />
              <Routes />
            </CustomPropsProvider>
          </ToasterProvider>
        </ThemeProvider>
      </FlagsmithProvider>
    );
  }
}
