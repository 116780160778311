import axios, { AxiosInstance } from 'axios';
import { responseErrorInterceptor, requestInterceptor } from '../Interceptors';
import { envVars } from '../../envVars';

export const api: AxiosInstance = axios.create({
  baseURL: envVars.FILE_ENGINE_APP_URL,
  timeout: 300000,
});
api.interceptors.response.use(response => response, responseErrorInterceptor);
api.interceptors.request.use(requestInterceptor);
