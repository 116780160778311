import React, { useContext, Suspense, FC } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { LoanSelection } from './pages';

import { CustomPropsContext } from './contexts/CustomPropsContext';

export const Routes: FC = () => {
  const { basePath } = useContext(CustomPropsContext);

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <Route path={`${basePath}/selecao-de-contratos`} exact component={LoanSelection} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
