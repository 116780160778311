import React, { useState } from 'react';

import { useAsyncCallback, useAsync } from 'react-async-hook';
import { useTranslation } from '@creditas/i18n';
import { useToaster } from '@creditas/toaster';
import { PageWrapper, gridContainerOptions } from './LoanSelection.style';
import { PageTitle } from './components/PageTitle';
import { LoanSelectionForm } from './components/LoanSelectionForm';
import { ConfirmLoanSelectionModal } from './components/ConfirmLoanSelectionModal';
import { performLoanSelection, getLoanSelections } from '../../services/file-engine/FileEngine';
import { Table } from './components/Table';

export const LoanSelection: React.FC = () => {
  const { push } = useToaster();
  const { t } = useTranslation();
  const headerTitles = [
    t('pages.loanSelection.table.header.assignor'),
    t('pages.loanSelection.table.header.assignee'),
    t('pages.loanSelection.table.header.product'),
    t('pages.loanSelection.table.header.totalValue'),
    t('pages.loanSelection.table.header.numberOfLoans'),
    t('pages.loanSelection.table.header.createdAt'),
    t('pages.loanSelection.table.header.actions'),
  ];

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const {
    result: loanSelections,
    loading: loadingSelections,
    execute: getLoanSelectionsFunction,
  } = useAsync(() => getLoanSelections(page, pageSize), [page, pageSize]);
  const {
    result: loanSelection,
    loading,
    execute: performLoanSelectionFunction,
    error,
  } = useAsyncCallback(performLoanSelection, {
    onSuccess: result => {
      getLoanSelectionsFunction();
      if (result.status === 201)
        push(t('pages.loanSelection.toaster.success'), { level: 'success' });
    },
    onError: e =>
      e.message === 'Request failed with status code 422'
        ? push(t('pages.loanSelection.toaster.noLoansFound'), { level: 'danger' })
        : push(t('pages.loanSelection.toaster.error'), { level: 'danger' }),
  });

  const [showConfirmSelectionModal, setShowConfirmSelectionModal] = useState(false);

  const [loanSelectionRequest, setLoanSelectionRequest] = useState({
    assignorId: '',
    assigneeId: '',
    productType: '',
    maxLoansValue: '',
    maxLoanQuantity: '',
    searchByProtocoledAt: false,
  });

  return (
    <PageWrapper options={gridContainerOptions}>
      <PageTitle title={t('pages.loanSelection.title')} />
      <LoanSelectionForm
        loanSelectionRequest={loanSelectionRequest}
        setLoanSelectionRequest={setLoanSelectionRequest}
        isLoading={loading}
        performLoanSelection={performLoanSelectionFunction}
        setShowConfirmSelectionModal={setShowConfirmSelectionModal}
      />
      {!error && !loading && loanSelection?.data && (
        <ConfirmLoanSelectionModal
          loanSelection={loanSelection.data}
          loanSelectionRequest={loanSelectionRequest}
          showModal={showConfirmSelectionModal}
          setShowModal={setShowConfirmSelectionModal}
          performLoanSelection={performLoanSelectionFunction}
        />
      )}
      <Table
        headers={headerTitles}
        loading={loadingSelections}
        data={loanSelections?.data}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        getLoanSelections={getLoanSelectionsFunction}
      />
    </PageWrapper>
  );
};
