import { gridMedia } from '../../../../utils/grid-utils';

export const gridInputSelectAssignor = {
  gridColumn: gridMedia('1 / 13', '1 / 5'),
  gridRow: gridMedia('1', '1'),
};

export const gridInputSelectAssignee = {
  gridColumn: gridMedia('1 / 13', '5 / 9'),
  gridRow: gridMedia('2', '1'),
};

export const gridInputSelectProduct = {
  gridColumn: gridMedia('1 / 13', '9 / 13'),
  gridRow: gridMedia('3', '1'),
};

export const gridInputFieldMaxValue = {
  gridColumn: gridMedia('1 / 13', '1 / 5'),
  gridRow: gridMedia('4', '2'),
};

export const gridInputFieldMaxContracts = {
  gridColumn: gridMedia('1 / 13', '5 / 9'),
  gridRow: gridMedia('5', '2'),
};

export const gridInputFieldMinValue = {
  gridColumn: gridMedia('1 / 13', '1 / 5'),
  gridRow: gridMedia('5', '3'),
};

export const gridCheckbox = {
  gridColumn: gridMedia('1 / 13', '5 / 9'),
  gridRow: gridMedia('7', '3'),
};

export const gridButton = {
  gridColumn: gridMedia('1 / 13', '1 / 5'),
  gridRow: gridMedia('8', '4'),
};

export const marginTopButton = {
  marginTop: '1rem',
};

export const gridFormOptions = {
  gridJustifyContent: 'center',
  gridColumns: {
    xs: 'repeat(12, 1fr)',
    md: 'repeat(12, 1fr)',
  },
  gridRowGap: {
    xs: '10px',
    md: '10px',
  },
};

export const gridForm = {
  gridColumn: gridMedia('1 / 12', '1 / 12'),
  gridRow: gridMedia('2', '2'),
};

export const gridInputFieldMaxLoanFinanceAmount = {
  gridColumn: gridMedia('1 / 13', '9 / 13'),
  gridRow: gridMedia('6', '2'),
};
