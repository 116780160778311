import { Product, tProductId } from '../../@types/product';

const products: Product[] = [
  {
    id: 'home',
    name: 'Home',
  },
  {
    id: 'bcredi',
    name: 'BCredi',
  },
  {
    id: 'auto',
    name: 'Auto',
  },
  {
    id: 'moto',
    name: 'Moto',
  },
  {
    id: 'pension',
    name: 'Previdência',
  },
  {
    id: 'payroll',
    name: 'Consignado',
  },
  {
    id: 'salary_anticipation',
    name: 'Antecipação de Salário',
  },
];

export const getProducts = (): Product[] => {
  return products;
};

export const getProductById = (id: tProductId): Product | undefined => {
  return products.find(product => product.id === id);
};
