import React from 'react';
import { GridItem } from '@creditas/layout';
import { gridPageTitle, StyledTypography } from './PageTitle.style';

interface PageTitleProps {
  title: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <GridItem gridColumn={gridPageTitle.gridColumn} gridRow={gridPageTitle.gridRow}>
      <StyledTypography variant="h4">{title}</StyledTypography>
    </GridItem>
  );
};
