/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button } from '@creditas/button';
import { InputField, InputSelect } from '@creditas/form-elements';
import { Grid, GridItem } from '@creditas/layout';
import { Form, withFormik, FormikProps } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from '@creditas/i18n';
import { AxiosResponse } from 'axios';
import { Checkbox } from '@creditas/checkbox';
import { InputSelectOption } from '@creditas/form-elements/dist/InputSelectOption';
import * as Yup from 'yup';
import { styled } from '@creditas/stylitas';
import CurrencyFormat from 'react-currency-format';
import {
  gridFormOptions,
  gridInputSelectAssignor,
  gridInputFieldMaxValue,
  gridInputFieldMinValue,
  gridInputSelectAssignee,
  gridInputSelectProduct,
  gridButton,
  gridCheckbox,
  gridInputFieldMaxContracts,
  gridForm,
  marginTopButton,
  gridInputFieldMaxLoanFinanceAmount,
} from './LoanSelectionForm.style';
import { LoanSelectionResponse, LoanSelectionRequest } from '../../../../@types/loan-selection';
import {
  getAssigneeById,
  getAssignees,
  getAssignorById,
  getAssignors,
} from '../../../../services/fund/Fund';
import { getProducts } from '../../../../services/product';
import { FundResponse } from '../../../../@types/fund';
import { Product, tProductId } from '../../../../@types/product';

const SpanError = styled.span`
  color: red;
`;

interface LoanSelectionFormProps {
  loanSelectionRequest: LoanSelectionRequest;
  setLoanSelectionRequest: (request: LoanSelectionRequest) => void;
  isLoading: boolean;
  performLoanSelection: (
    request: LoanSelectionRequest,
  ) => Promise<AxiosResponse<LoanSelectionResponse>>;
  setShowConfirmSelectionModal: (value: boolean) => void;
}

interface FormValues {
  assignorId: string;
  assigneeId: string;
  productType: string;
  maxLoansValue: string;
  maxLoanQuantity: string;
  searchByProtocoledAt: boolean;
  minLoanValue: string;
  maxLoanFinancedAmount: string;
}

const initialFilterValues: FormValues = {
  assignorId: '',
  assigneeId: '',
  productType: '',
  maxLoansValue: '',
  maxLoanQuantity: '',
  maxLoanFinancedAmount: '',
  searchByProtocoledAt: false,
  minLoanValue: '',
};

const InnerForm: React.FC<LoanSelectionFormProps & FormikProps<FormValues>> = ({
  loanSelectionRequest,
  setLoanSelectionRequest,
  handleChange,
  isLoading,
  setShowConfirmSelectionModal,
  errors,
  resetForm,
  submitCount,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(initialFilterValues);
  const [isProductTypeHomeOrBcredi, setIsProductTypeHomeOrBcredi] = useState(false);
  const [products] = useState(getProducts());
  const [assignees] = useState(getAssignees());
  const [assignors] = useState(getAssignors());
  const assignor = getAssignorById(filter.assignorId);
  const assignee = getAssigneeById(filter.assigneeId);

  const mapToInputSelectionOption = (element: FundResponse | Product): InputSelectOption => {
    return { value: element.id, text: element.name };
  };

  const hasAtLeastOneEqualProductId = (productIds1: tProductId[], productIds2: tProductId[]) =>
    productIds1.some(id1 => productIds2.some(id2 => id1 === id2));

  const isValidProduct = (product: Product) => {
    return (
      (filter.assignorId === '' || assignor?.products.includes(product.id)) &&
      (filter.assigneeId === '' || assignee?.products.includes(product.id))
    );
  };

  const validProducts: Product[] = products.filter(isValidProduct);

  const productOptions = (): InputSelectOption[] => validProducts.map(mapToInputSelectionOption);

  const isValidAssignor = (assignor: FundResponse): boolean => {
    if (assignor.id !== assignee?.id && assignor.active) {
      const { productType } = filter;
      return (
        (productType === '' || assignor.products.includes(productType as tProductId)) &&
        (!assignee || hasAtLeastOneEqualProductId(assignee.products, assignor.products))
      );
    }
    return false;
  };

  const validAssignors = assignors.filter(isValidAssignor);

  const assignorsOptions: InputSelectOption[] = validAssignors.map(mapToInputSelectionOption);

  const isValidAssignee = (assignee: FundResponse): boolean => {
    if (assignee.id !== assignor?.id && assignee.active) {
      if (assignor?.category === 'UNDERWRITER' && assignee.category === 'CRI') {
        return false;
      }
      const { productType } = filter;
      return (
        (productType === '' || assignee.products.includes(productType as tProductId)) &&
        (!assignor || hasAtLeastOneEqualProductId(assignee.products, assignor.products))
      );
    }
    return false;
  };

  const validAssignees: FundResponse[] = assignees.filter(isValidAssignee);

  const assigneesOptions: InputSelectOption[] = validAssignees.map(mapToInputSelectionOption);

  useEffect(() => {
    const { productType } = filter;
    const homeOrBcredi = ['home', 'bcredi'].includes(productType);
    if (!homeOrBcredi) {
      setFilter({ ...filter, searchByProtocoledAt: false, minLoanValue: '' });
      setLoanSelectionRequest({
        ...loanSelectionRequest,
        searchByProtocoledAt: false,
        minLoanValue: '',
      });
      resetForm({ values: filter });
    }
    setIsProductTypeHomeOrBcredi(homeOrBcredi);
  }, [filter.productType]);

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    handleChange(event);
    setFilter({ ...filter, [event.target.id]: event.target.value });
    setLoanSelectionRequest({ ...loanSelectionRequest, [event.target.id]: event.target.value });
  };

  const handleSearchByProtocoledAtOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { searchByProtocoledAt } = filter;
    handleChange(event);
    setFilter({ ...filter, searchByProtocoledAt: !searchByProtocoledAt });
    setLoanSelectionRequest({
      ...loanSelectionRequest,
      searchByProtocoledAt: !searchByProtocoledAt,
    });
  };

  return (
    <GridItem gridColumn={gridForm.gridColumn} gridRow={gridForm.gridRow}>
      <Form data-testid="loan-selection-form">
        <Grid options={gridFormOptions}>
          <GridItem
            gridColumn={gridInputSelectAssignor.gridColumn}
            gridRow={gridInputSelectAssignor.gridRow}
          >
            <InputSelect
              id="assignorId"
              width="1fr"
              options={assignorsOptions}
              onChange={handleOnChange}
              name="assignorId"
              label={t('pages.loanSelection.labels.assignor')}
              data-testid="assignorId"
            />
            {submitCount > 0 && errors.assignorId && (
              <SpanError>{t('pages.loanSelection.validation.assignor')}</SpanError>
            )}
          </GridItem>

          <GridItem
            gridColumn={gridInputSelectAssignee.gridColumn}
            gridRow={gridInputSelectAssignee.gridRow}
          >
            <InputSelect
              id="assigneeId"
              width="1fr"
              options={assigneesOptions}
              onChange={handleOnChange}
              name="assigneeId"
              label={t('pages.loanSelection.labels.assignee')}
              data-testid="assigneeId"
            />
            {submitCount > 0 && errors.assigneeId && (
              <SpanError>{t('pages.loanSelection.validation.assignee')}</SpanError>
            )}
          </GridItem>
          <GridItem
            gridColumn={gridInputSelectProduct.gridColumn}
            gridRow={gridInputSelectProduct.gridRow}
          >
            <InputSelect
              width="1fr"
              options={productOptions()}
              onChange={handleOnChange}
              name="productType"
              id="productType"
              label={t('pages.loanSelection.labels.product')}
              data-testid="productType"
            />
            {submitCount > 0 && errors.productType && (
              <SpanError>{t('pages.loanSelection.validation.product')}</SpanError>
            )}
          </GridItem>
          <GridItem
            gridColumn={gridInputFieldMaxValue.gridColumn}
            gridRow={gridInputFieldMaxValue.gridRow}
          >
            <InputField
              id="maxLoansValue"
              width="1fr"
              onChange={handleOnChange}
              name="maxLoansValue"
              label={t('pages.loanSelection.labels.maxLoansValue')}
            />
          </GridItem>
          <GridItem
            gridColumn={gridInputFieldMaxContracts.gridColumn}
            gridRow={gridInputFieldMaxContracts.gridRow}
          >
            <InputField
              id="maxLoanQuantity"
              width="1fr"
              onChange={handleOnChange}
              name="maxLoanQuantity"
              label={t('pages.loanSelection.labels.maxLoanQuantity')}
            />
          </GridItem>
          <GridItem
            gridColumn={gridInputFieldMaxLoanFinanceAmount.gridColumn}
            gridRow={gridInputFieldMaxLoanFinanceAmount.gridRow}
          >
            <CurrencyFormat
              customInput={InputField}
              prefix="R$ "
              name="maxLoanFinancedAmount"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              id="maxLoanFinancedAmount"
              width="1fr"
              onChange={handleOnChange}
              label={t('pages.loanSelection.labels.maxLoanFinancedAmount')}
              fixedDecimalScale
            />
          </GridItem>
          <GridItem
            gridColumn={gridInputFieldMinValue.gridColumn}
            gridRow={gridInputFieldMinValue.gridRow}
            hidden={isProductTypeHomeOrBcredi ? '' : 'hidden'}
          >
            <InputField
              width="1fr"
              id="minLoanValue"
              value={filter.minLoanValue}
              onChange={handleOnChange}
              name="minLoanValue"
              label={t('pages.loanSelection.labels.minLoanValue')}
            />
          </GridItem>
          <GridItem
            gridColumn={gridCheckbox.gridColumn}
            gridRow={gridCheckbox.gridRow}
            hidden={isProductTypeHomeOrBcredi ? '' : 'hidden'}
          >
            <Checkbox
              id="searchByProtocoledAt"
              boxed
              checked={filter.searchByProtocoledAt}
              onChange={handleSearchByProtocoledAtOnChange}
              name="searchByProtocoledAt"
            >
              {t('pages.loanSelection.labels.searchByProtocoledAt')}
            </Checkbox>
          </GridItem>
          <GridItem
            style={marginTopButton}
            gridColumn={gridButton.gridColumn}
            gridRow={gridButton.gridRow}
          >
            <Button
              data-testid="select-contracts-button"
              isLoading={isLoading}
              width="1fr"
              onClick={(): void => {
                if (Object.keys(errors).length === 0) setShowConfirmSelectionModal(true);
              }}
            >
              {t('pages.loanSelection.button.selectContracts')}
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </GridItem>
  );
};

const cleanupMaxLoanFinanceAmount = (value: string): number | undefined => {
  if (!value) return undefined;
  const result = value.replace('R$', '');
  return parseFloat(result.replace('.', '').replace(',', '.'));
};

const LoanSelectionFormSchema = Yup.object().shape({
  assignorId: Yup.string().required('Required'),
  assigneeId: Yup.string().required('Required'),
  productType: Yup.string().required('Required'),
});

export const LoanSelectionForm = withFormik<LoanSelectionFormProps, FormValues>({
  handleSubmit: (values: FormValues, formAttributes) =>
    formAttributes.props.performLoanSelection({
      assignorId: values.assignorId,
      assigneeId: values.assigneeId,
      productType: values.productType,
      maxLoansValue: values.maxLoansValue,
      maxLoanQuantity: values.maxLoanQuantity,
      searchByProtocoledAt: values.searchByProtocoledAt,
      minLoanValue: values.minLoanValue,
      maxLoanFinancedAmount: cleanupMaxLoanFinanceAmount(values.maxLoanFinancedAmount),
    }),
  validationSchema: () => LoanSelectionFormSchema,
})(InnerForm);
