import { AxiosError, AxiosRequestConfig } from 'axios';
import { getAccessToken, logout } from './auth/auth';

export const requestInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig => {
  const accessToken = getAccessToken();

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers['Content-Type'] = 'application/json';
  }
  return request;
};

export const responseErrorInterceptor = (error: AxiosError): Promise<never> => {
  if (error.response?.status === 401) {
    logout();
  }
  return Promise.reject(error);
};
